import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { clear } from "console";

export interface IPaginationState {
  page: number;
  limit: number;
  count: number;
  lastVisitedPage: number;
  paginatedData: Record<
    number,
    {
      data: any[];
      filters: Record<string, string | number>;
    }
  >;
  filters: Record<string, string | number>;
}

const initialState: IPaginationState = {
  page: 1,
  limit: 10,
  count: 0,
  lastVisitedPage: 1,
  paginatedData: {},
  filters: {},
};

const PaginationSlice = createSlice({
  name: "SurveyQuestionPagination",
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    setCount: (state, action: PayloadAction<number>) => {
      state.count = action.payload;
    },
    setFilters: (
      state,
      action: PayloadAction<Record<string, string | number>>,
    ) => {
      state.filters = action.payload;
    },
    setPaginatedData: (
      state,
      action: PayloadAction<{ page: number; data: any }>,
    ) => {
      const { page, data }: { page: number; data: any[] } = action.payload;
      state.paginatedData[page] = {
        data,
        filters: state.filters,
      };
    },
    clearPagination: (state) => {
      state.page = 1;
      state.limit = 10;
      state.count = 0;
      state.lastVisitedPage = 1;
      state.paginatedData = {};
      state.filters = {};
    },
  },
});

export const {
  setPage,
  setLimit,
  setCount,
  setFilters,
  setPaginatedData,
  clearPagination,
} = PaginationSlice.actions;

export const selectPagination = (state: {
  SurveyQuestionPagination: IPaginationState;
}) => state.SurveyQuestionPagination;

export default PaginationSlice.reducer;
