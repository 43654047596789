import { Avatar, AvatarProps, Box, Button, styled } from "@mui/material";
import { FC } from "react";
import { useModalContext } from "../contexts/ModalDialogContext";
import ModalDialog from "./UI/Modal/ModalDialog";
import uniqueId from "../utils/generateId";
import CloseIcon from "@mui/icons-material/Close";
import { position } from "stylis";

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  borderColor:
    theme.palette.mode === "light"
      ? theme.palette.secondary[300]
      : theme.palette.divider,
  backgroundColor:
    theme.palette.mode === "light"
      ? theme.palette.secondary[300]
      : theme.palette.primary[200],
}));

interface IUkoAvatar extends AvatarProps {
  clickable?: boolean;
}

const UkoAvatar: FC<IUkoAvatar> = ({ clickable = false, ...props }) => {
  const { setModalId, setShowModal, showModal } = useModalContext();

  const handleOpenModal = () => {
    setModalId(`avatar-full-view-${props?.src}`);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModalId("");
  };

  return (
    <Box sx={{ cursor: clickable ? "pointer" : "default" }}>
      <StyledAvatar
        onClick={clickable ? handleOpenModal : () => {}}
        {...props}
      />
      {showModal && (
        <ModalDialog
          selectedModalId={`avatar-full-view-${props?.src}`}
          title={""}
          onClose={handleCloseModal}
          id={uniqueId()}
          ActionButtons={() => <></>}
          showModalTitle={false}
          showModalBottomActions={false}
          minWidth={500}
          customStyles={{
            padding: "0px !important",
            borderRadius: 0,
            "& .MuiDialogContent-root.MuiDialogContent-dividers": {
              padding: "0px !important",
              borderRadius: 0,
              borderTop: "0px !important",
              borderBottom: "0px !important",
            },
            "& .MuiAvatar-root.MuiAvatar-circular": {
              width: "500px",
              height: "500px",
              borderRadius: 0,
              border: "0px",
            },
          }}
        >
          <Avatar {...props} />
          <Button
            onClick={handleCloseModal}
            color="inherit"
            variant={"contained"}
            sx={{ ...styles.closeButton, marginLeft: "auto" }}
          >
            <CloseIcon />
          </Button>
        </ModalDialog>
      )}
    </Box>
  );
};

const styles = {
  closeButton: {
    position: "absolute",
    top: 15,
    right: 15,
    borderRadius: "50%",
    padding: "0 !important",
    width: "2rem",
    height: "2rem",
    minWidth: "2rem",
    backgroundColor: "white",

    "&:hover": {
      backgroundColor: "inherit.main",
      color: "black",
    },
  },
};

export default UkoAvatar;
