export const hasAccess = (institution: any, permissions: string[]) => {
  let canAccess: boolean = false;

  if (!permissions || permissions?.length === 0) {
    canAccess = true;
  } else {
    const hasPermission = (permission: string) => {
      return institution?.all_permissions?.includes(permission);
    };
    canAccess = permissions.some(hasPermission);
  }

  return canAccess;
};
